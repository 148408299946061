<template>
<div>
    <h2>Mediums</h2>
    <div class="d-flex justify-content-center flex-wrap">
        <BLabel
            class="clickable"
            shape="fa-square"
            icon="fa-book-open"
            :colors="{outer: 'c-blue'}"
            v-tippy
            :content="counts.books"
            :activated="book"
            @click="changeValue('book')"
        />
        <BLabel
            class="clickable"
            shape="fa-square"
            icon="fa-film"
            :colors="{outer: 'c-blue'}"
            v-tippy
            :content="counts.anime"
            :activated="anime"
            @click="changeValue('anime')"
        />
    </div>
    <div class="d-flex justify-content-center flex-wrap">
        <BLabel
            class="clickable"
            shape="fa-square"
            icon="fa-gamepad"
            :colors="{outer: 'c-blue'}"
            v-tippy
            :content="counts.games"
            :activated="game"
            @click="changeValue('game')"
        />
        <BLabel
            class="clickable"
            shape="fa-square"
            icon="fa-archive"
            :colors="{outer: 'c-blue'}"
            v-tippy
            :content="counts.uncategorized"
            :activated="other"
            @click="changeValue('other')"
        />
    </div>
</div>
</template>

<script>
import BLabel from '../../Shared/ButtonWithLabel.vue'

export default {
    name: 'Mediums',
    props: ['books'],
    data() {
        return {
            anime: true,
            game: true,
            book: true,
            other: true,
            firstValueChange: true
        }
    },
    components: {
        BLabel
    },
    computed: {
        counts() {
            const { books } = this
            return {
                books: `${books.filter((i) => i.medium === 'b').length} books`,
                anime: `${books.filter((i) => i.medium === 'a').length} anime`,
                games: `${books.filter((i) => i.medium === 'v').length} games`,
                uncategorized: `${books.filter((i) => i.medium === 'o').length} uncategorized`
            }
        }
    },
    methods: {
        changeValue(prop) {
            if (this.firstValueChange) {
                this.anime = false
                this.game = false
                this.book = false
                this.other = false
                this.firstValueChange = false
                this[prop] = true
                return
            }
            this[prop] = !this[prop]
        },
        getResults() {
            const activeMediums = []
            if (this.anime) activeMediums.push('a')
            if (this.book) activeMediums.push('b')
            if (this.game) activeMediums.push('v')
            if (this.other) activeMediums.push('o')
            return activeMediums
        },
        syncResults() {
            this.$emit('changed-active-mediums', this.getResults())
        }
    },
    watch: {
        anime() {
            this.syncResults()
        },
        other() {
            this.syncResults()
        },
        game() {
            this.syncResults()
        },
        book() {
            this.syncResults()
        }
    }
}
</script>
