<template>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <div>
            <span class="badge bg-info">{{unique}}</span>
            <span v-if="item.unknown_count" class="badge bg-info">{{unknown}}</span>
            <span v-if="item.name" class="badge bg-success">private book</span>
            <span v-if="'readability' in item && item.readability !== 0" class="badge bg-info" v-tippy content="Readability based on Hayashi score. Higher is easier.">Readability {{item.readability.toFixed(2)}}</span>
        </div>
        <div v-if="series || aired" class="marg-small">
            <span v-if="item.isSeries" v-tippy :content="series.series"
            >
                <span class="badge bg-success">{{`Vol. ${series.volume}`}}</span>
            </span>
            <span v-if="aired" v-tippy content="Aired on"
            >
                <span class="badge bg-success">{{aired.val}}</span>
            </span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ModalStats',
    data() {
        return {}
    },
    props: ['item', 'series', 'aired'],
    computed: {
        unique() {
            return `unique ${this.item.word_count}` || 'no data'
        },
        unknown() {
            if (!this.item.unknown_count === -1) return 'calculating (or not logged in)'
            if (this.item.unknown_count > this.item.word_count) return `unknown ${this.unique}`
            return `unknown ${this.item.unknown_count}`
        }
    }
}
</script>

<style lang="sass" scoped>
.badge
    margin-right: .5em
</style>
