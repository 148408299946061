<template>
    <div class="w-100 pad-small title-outline">
        <h2 v-if="!options.showingUnreleased && options.unreleased" v-html="comingSoonHTML"></h2>
        <h2 v-else :lang="options.romaji ? 'en' : 'jp'">{{title}}</h2>
    </div>
</template>

<script>
export default {
    name: 'CoverTitle',
    props: ['options', 'items'],
    computed: {
        title() {
            const { items } = this
            if (!this.options.romaji) return items.title_jp.length > 33 ? `${items.title_jp.substr(0, 33)}…` : items.title_jp
            return items.title.length > 33 ? `${items.title.substr(0, 33)}…` : items.title
        },
        comingSoonHTML() {
            const numTitles = this.items.title.match(/(.*) Coming/)
            const htmlWrapper = { before: '<span style="color: orange;">', after: '</span>' }
            if (numTitles) {
                return this.items.title
                        .replace(numTitles[1], `${htmlWrapper.before}${numTitles[1]}${htmlWrapper.after}`)
            }
            return this.title
        },
    }
}
</script>
