<template>
    <div>
        <h2>Filters</h2>
        <div class="d-flex justify-content-center flex-wrap">
            <!--filter by category-->
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-heart"
                :colors="{outer: 'c-blue'}"
                v-tippy
                content="Show 'want to read'"
                :activated="wtr"
                @click="changeValue('wtr')"
            />
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-book"
                :colors="{outer: 'c-blue'}"
                v-tippy
                content="Show 'already read'"
                :activated="read"
                @click="changeValue('read')"
            />
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-lock"
                :colors="{outer: 'c-blue'}"
                v-tippy
                content="Show privately owned books"
                :activated="owned"
                @click="changeValue('owned')"
            />
        <!--EOR-->
        </div>
        <div class="d-flex justify-content-center flex-wrap">
            <!--filter by category-->
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-dollar-sign"
                :colors="{outer: 'c-blue'}"
                v-tippy
                content="Show free resources"
                :activated="free"
                @click="changeValue('free')"
            />
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-child"
                :colors="{outer: 'c-blue'}"
                v-tippy
                content="Show easy resources only"
                :activated="easy"
                @click="changeValue('easy')"
            />
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fas fa-comments"
                :colors="{outer: 'c-blue'}"
                v-tippy
                content="Show Wanikani book club books"
                :activated="club"
                @click="changeValue('club')"
            />
        <!--EOR-->
        </div>
    </div>
</template>

<script>
import BLabel from '../../Shared/ButtonWithLabel.vue'

export default {
    name: 'Favorites',
    components: {
        BLabel,
    },
    data() {
        return {
            wtr: false,
            read: false,
            owned: false,
            free: false,
            easy: false,
            club: false,
            firstValueChange: true,
            listExpanded: false,
        }
    },
    methods: {
        changeValue(prop) {
            if (this.firstValueChange) {
                this.wtr = false
                this.read = false
                this.owned = false
                this.firstValueChange = false
                this[prop] = true
                return
            }
            this[prop] = !this[prop]
        },
        getResults() {
            const activeFilters = []
            if (this.wtr) activeFilters.push('wtr')
            if (this.read) activeFilters.push('fav') // legacy
            if (this.owned) activeFilters.push('owned')
            if (this.free) activeFilters.push('free')
            if (this.easy) activeFilters.push('easy')
            if (this.club) activeFilters.push('club')
            return activeFilters
        },
        syncResults() {
            this.$emit('changed-active-filters', this.getResults())
        }
    },
    watch: {
        wtr() {
            this.syncResults()
        },
        read() {
            this.syncResults()
        },
        owned() {
            this.syncResults()
        },
        free() {
            this.syncResults()
        },
        easy() {
            this.syncResults()
        },
        club() {
            this.syncResults()
        },
    }
}
</script>
