export default {
    notLoggedIn: {
        header: 'Introduction',
        body: 'Koohi.cafe is a website for finding vocabulary lists based on Japanese media. You can use these lists to instantly create reviewable flashcards.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    findingABook: {
        header: 'Finding a List',
        body: 'To find a list, click a cover to see more information. Then, click the glowing <i class="fas fa-link text-dark"></i> button to be taken to the list creation page.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    bookClubs: {
        header: 'Finding Book Clubs',
        body: 'Koohi generally supports books from Wanikani books clubs.<br>1. Go to the options menu (<i class="fas fa-cog "></i>) near the search bar.<br>2. Enable the \'has book club\' (<i class="fas fa-comments "></i>) filter.<br>Discussion threads can be found by clicking on a cover, and following the link to the thread.',
    },
    loadingWords: {
        header: 'Updating Unknown Words',
        body: 'This will take a moment. If you use Wanikani levels you can set it in the options menu.',
        buttons: [{ color: 'btn-primary', text: 'Open options menu', action: () => { this.modals.bookFilters.toggle() } }]
    }
}
