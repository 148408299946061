<template>
    <div>
        <div class="fa-stack fa d-block clickable marg-small"
        v-if="loggedIn"
            @click.stop.prevent="handleFlagChange({id: items.id, flag: 'wtr'})"
            v-tippy="{ placement: 'right' }"
            content="Want to read"
        >
            <i class="fa fa-circle fa-stack-2x icon-background"></i>
            <i class="fa fa-heart fa-stack-1x" :class="items.flag.includes('wtr') ? 'c-blue' : 'c-white' "></i>
        </div>
        <div class="fa-stack fa d-block clickable marg-small"
        v-if="loggedIn"
            @click.stop.prevent="handleFlagChange({id: items.id, flag: 'fav'})"
            v-tippy="{ placement: 'right' }"
            content="Already read"
        >
            <i class="fa fa-circle fa-stack-2x icon-background"></i>
            <i class="fa fa-book fa-stack-1x" :class="items.flag.includes('fav') ? 'c-blue' : 'c-white' "></i>
        </div>
        <div class="fa-stack fa d-block clickable marg-small"
            @click.stop.prevent="$emit('set-modal', items.id)"
            v-tippy="{ placement: 'right' }"
            content="More info"
        >
            <i class="fa fa-circle fa-stack-2x icon-background"></i>
            <i class="fa fa-expand fa-stack-1x c-white"></i>
        </div>
        <div class="fa-stack fa d-block clickable marg-small"
            v-tippy="{ placement: 'right' }"
            content="Go to vocab list"
            @click.stop
        >
            <router-link :to="{ path: '/list', query: { list: items.vocab_list } }">
                <i class="fa fa-circle fa-stack-2x icon-background"></i>
                <i class="fa fa-link fa-stack-1x c-red a-glow"></i>
            </router-link>
        </div>
    </div>
</template>

<script>
import { SS } from '@/assets/constants'

export default {
    name: 'CoverButtons',
    props: ['items'],
    methods: {
        handleFlagChange(opts) {
            const { id, flag } = opts
            /* if (!this.options.login) {
                return this.$emit('login-warning')
            } */
            return this.$emit('change-flag', { id, flag })
        }
    },
    computed: {
        loggedIn() {
            return !SS.get(SS.PUBLIC_API)
        }
    }
}
</script>
