<template>
    <div>
        <Search
            class="col-12 module"
            v-on:search-val-update="$emit('search-val-update', $event)"
            v-on:open-options-modal="$emit('open-options-modal')"
            :books="books"
        >
            <div slot="msg"><sup>{{`...${titleCount} Titles`}}</sup></div>
        </Search>
        <mq-layout mq="lg+" class="col-6 col-md-12 module">
            <Ordering
                v-on:changed-active-ordering="$emit('changed-active-ordering', $event)"
            />
        </mq-layout>
        <Favorites
            class="col-6 col-md-12 module"
            v-on:changed-active-filters="$emit('changed-active-filters', $event)"
        />
        <Mediums
            class="col-6 col-md-12 module"
            :books="books"
            v-on:changed-active-mediums="$emit('changed-active-mediums', $event)"
        />
    </div>
</template>

<script>
import Ordering from './Ordering.vue'
import Favorites from './Favorites.vue'
import Mediums from './Mediums.vue'
import Search from '../Search.vue'

export default {
    name: 'Side',
    props: [
        'books',
    ],
    components: {
        Search,
        Ordering,
        Favorites,
        Mediums,
    },
    data() {
        return {
            searchFieldValue: "",
        }
    },
    computed: {
        booksToShowForDatalist() {
            /* const sorter = (a, b) => {
                const x = new RegExp(`${this.useFilters.search}`)
                const firstMatch = a.match(x)
                const secondMatch = b.match(x)
                if (firstMatch && secondMatch) return 0
                if (firstMatch && !secondMatch) return 1
                return -1
            }
            if (wanakana.isJapanese(this.useFilters.search)) {
                return this.booksToShow.released.map((i) => i.title_jp).slice(0, 12).sort(sorter)
            }
            return this.booksToShow.released.map((i) => i.title).slice(0, 12).sort() */
            return []
        },
        titleCount() {
            return this.books
                    .filter((i) => !i.hidden)
                    .length
        }
    },
    methods: {
    }
}
</script>
