import { render, staticRenderFns } from "./Breadcrumbs-UI.vue?vue&type=template&id=5e68f1ee&scoped=true&"
import script from "./Breadcrumbs-UI.vue?vue&type=script&lang=js&"
export * from "./Breadcrumbs-UI.vue?vue&type=script&lang=js&"
import style0 from "./Breadcrumbs-UI.vue?vue&type=style&index=0&id=5e68f1ee&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e68f1ee",
  null
  
)

export default component.exports