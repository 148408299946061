<template>
<div>
    <div class="book-card col-12 pad-small card-base" @click="handleCoverClick()" :id="`book${items.id}${cancelSeriesCollapse ? 'noScroll' : ''}`">
        <div class="container-relative">
            <img :src="getImg(items)" @error="items.filename = 'cover.jpg' " :alt="`${items.title} - Vocabulary List`" />
            <!--difficulty counter-->
            <CoverBadges
                :items="items"
                v-on:show-related="$emit('show-related', $event)"
            />
            <!--buttons-->
            <CoverButtons
                @click.stop="/*stop modal from going off*/"
                v-if="!items.until_release" class="d-none d-md-block pos-right"
                :items="items"
                v-on:change-flag="$emit('change-flag', $event)"
                v-on:set-modal="$emit('set-modal', $event)"
            />
            <!--unreleased-->
            <div v-else class="d-flex pos-center align-center">
                <div class="badge bg-jet d-flex align-center"><i class="fas fa-lock"></i></div>
                <div v-if="'until_release' in items" class="badge bg-jet align-right">
                    <div>Release:</div>
                    <div v-for="(digit, index) in items.until_release.split(' ')"
                        class="d-flex"
                        style="justify-content:flex-end"
                        :key="index"
                    >
                        <div>{{digit}}</div><div style="min-width:2em">{{index == 0 ? 'H' : 'M'}}</div><br />
                    </div>
                </div>
            </div>
            <div class="w-100 marg-small pad-small d-block align-center">
                <span class="fa-stack help" v-tippy :content="mediumText">
                    <i
                        class="fa fa-circle fa-stack-2x icon-background"
                        :class="publicItem ? 'c-blue' : 'c-green'"
                    ></i>
                    <i class="fa fa-stack-1x c-white" :class="`${mediumIcon}`"></i>
                </span>
                <span v-if="!publicItem"
                class="fa-stack help" v-tippy content="This is a custom-ordered book">
                    <i
                    class="fa fa-circle fa-stack-2x icon-background"
                    :class="publicItem ? 'c-blue' : 'c-green'"
                    ></i>
                    <i class="fas fa-stack-1x c-white fa-shopping-cart"></i>
                </span>
            </div>
        </div>
        <CoverTitle
            :items="items"
            :options="options"
        />
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import C from '@/assets/common'
import CoverButtons from './Cover-Buttons.vue'
import CoverBadges from './Cover-Badges.vue'
import CoverTitle from './Cover-Title.vue'

const {
    UseAPI, HandleRequestFail
} = C

export default {
    name: 'Cover',
    props: [
        'items',
        'options',
        'cancelSeriesCollapse',
        'unreleased'
    ],
    components: {
        CoverButtons,
        CoverBadges,
        CoverTitle
    },
    computed: {
        mediumIcon() {
            const { medium } = this.items
            if (medium === 'b') return 'fa-book-open'
            if (medium === 'a') return 'fa-film'
            if (medium === 'd') return 'fa-video'
            if (medium === 'o') return 'fa-archive'
            return 'fa-gamepad'
        },
        mediumText() {
            const { medium } = this.items
            if (medium === 'b') return 'Book'
            if (medium === 'a') return 'Anime'
            if (medium === 'd') return 'Drama/Film'
            if (medium === 'o') return 'Other'
            return 'Game/VN'
        },
        publicItem() {
            if (this.items?.permission === 'public') return true
            return false
        }
    },
    created() {
        if (this.debug) console.log('%cCreated cover', window.ConsoleStyles.createdComponent, this)
    },
    data() {
        return {
            debug: false
        }
    },
    methods: {
        handleCoverClick() {
            if (this.unreleased) {
                this.$emit('cover-click')
            }
            if (this.items.series && !this.cancelSeriesCollapse) {
                this.$emit('expand-series', {
                    series: this.items.series,
                    element: `book${this.items.id}`
                })
                return
            }
            this.$emit('set-modal', this.items.id)
        },
        goToListPage() {
            const bookList = this.items.vocab_list;
            this.$router.push({ path: '/list', query: { list: bookList } })
        },
        getImg(items) {
            try {
                /* return require(`@/assets/images/covers/${items.filename ? items.filename : 'cover.jpg'}`) */
                if (items.filename === 'def') return require(`@/assets/svg/coffee vector.svg`)
                return require(`@/assets/images/covers/${items.filename}`)
            } catch (e) {
                this.$emit('document-warning', `Image not found ${items.filename} for book ${items.title}`)
                return require(`@/assets/svg/coffee vector.svg`)
            }
        },
    },
    mounted() {
        const id = `book${this.items.id}`
        this.$emit('mounted', id)
    }
}
</script>

<style lang="sass">
.book-card
    @media (max-width: 768px)
        h2
            font-size: 1em
.card-base
    flex-direction: column
    display: flex
    overflow: hidden
.top-badges
    position: absolute
    top: .5em
    right: .5em
    text-align: right
    width: 100%
    .badge.clickable:hover
        filter: brightness(1.5)
.pos-right
    left: 85%
/* hover controls not active on mobile */
@media (min-width: 992px)
    .book-card:hover
        .pos-right
            .fa-stack
                &:hover
                    filter:brightness(1.2)
                .fa-circle
                    filter: drop-shadow(2px 6px 8px #232323)
</style>
