<template>
    <div class="col-6 col-sm-4 col-lg-3 marg-big anim-float d-flex clickable" @click="$emit('open-custom-order-modal')">
        <div class="book-card col-12 pad-small card-base">
                <img :src="require(`@/assets/svg/coffee vector.svg`)"
                style="filter: saturate(.3); padding: 10px; box-shadow: 0px -2px 15px 0 inset rgba(0,0,0,.25); min-height: 73.5%;"
                />
            <div class="container-relative">
                <div class="w-100 marg-small pad-small d-block align-center">
                    <span class="fa-stack help">
                        <i class="fa fa-circle fa-stack-2x icon-background c-blue"></i>
                        <i class="fa fa-stack-1x c-white" :class="`fa-shopping-cart`"></i>
                    </span>
                </div>
            </div>
            <div class="w-100 pad-small title-outline">
                <h2 style="color: orange;">Custom Lists</h2>
                <div v-if="saleDate" class="align-center"><span class="badge bg-success">On Sale</span></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomBook',
    data() {
        return {
            saleDate: null
        }
    },
    created() {
        /* sale date */
        const curDate = new Date()
        const curDateDate = curDate.getDate()
        if (curDateDate >= 1 && curDateDate <= 7) this.saleDate = true
    }
}
</script>
