<template>
    <div id="bookList">
        <Modal :login="flags.login" :item="flags.modalItem ? flags.modalItem : books[0]" :meta="bookMetaToShow"
            v-on:login-warning="showLoginWarning()" v-on:flag-change="modifyFlag($event)" />
        <CustomOrder />
        <Options :opts="opts" v-on:toggle-romaji="opts.romaji = !opts.romaji" v-on:set-wk="setWK($event)"
            v-on:changed-active-ordering="$emit('changed-active-ordering', $event)" />
        <Toasts v-on:hide-toasts="1 == 1" :toasts="toasts" v-on:close-all="toasts = []"
            v-on:disable-help="[opts.tutorial = false, toasts = []]" />
        <div class="row">
            <div id="main" class="order-2 order-md-1 col-12 col-md-9">
                <Spinner v-if="!flags.loaded" :badge="'bg-success'" :message="'Loading'" />
                <div v-else class="row">
                    <Gallery v-on:open-custom-order-modal="modals.customOrder.toggle()"
                        v-on:expand-series="flags.breadcrumbs = { ...flags.breadcrumbs, ...$event }"
                        v-on:trigger-scroll="scrollInterface($event)" :flags="flags" :books="books" :opts="opts"
                        :meta="bookMeta" :ordering="side.ordering" :searchVal="side.search" :activeMediums="side.mediums"
                        :activeFilters="side.filters" v-on:set-modal="setModal($event)"
                        v-on:login-warning="showLoginWarning()" v-on:change-flag="modifyFlag($event)"
                        v-on:document-warning="documentWarning($event)" />
                    <!-- your book here -->
                    <div class="w-100"></div>
                    <!--EOR-->
                </div>
            </div>
            <!--side-->
            <div id="side" class="order-1 order-md-2 col-12 col-md-3">
                <Side id="book_sidebar" class="row" :books="books" v-on:open-options-modal="modals.bookFilters.toggle()"
                    v-on:search-val-update="side.search = $event" v-on:changed-active-ordering="side.ordering = $event"
                    v-on:changed-active-mediums="side.mediums = $event"
                    v-on:changed-active-filters="side.filters = $event" />
            </div>
        </div>
    </div>
</template>

<script>
import { Modal as Bmodal } from 'bootstrap'
import { SS } from '@/assets/constants'
import getSavedOptions from '@/assets/js/getSavedOptions'
import TOAST_CONFIG from '@/assets/js/toastsConfig/books'
import { UseAPI, HandleRequestFail, SendUserAlert } from '@/assets/common'
import ApiPreloader from '@/assets/preloader'
import Gallery from './Gallery/Gallery.vue'
import Side from './Side/Side.vue'
import Modal from './Modal/Modal.vue'
import Options from './Options.vue'
import CustomOrder from './CustomOrder/CustomOrder.vue'
import Spinner from '../Spinner.vue'
import Toasts from '../Shared/Toasts.vue'

export default {
    name: 'Books',
    components: {
        Gallery,
        Side,
        Modal,
        Options,
        Toasts,
        CustomOrder,
        Spinner,
    },
    computed: {
        bookMetaToShow() {
            if (!this.flags.modalItem) return []
            // eslint-disable-next-line eqeqeq
            return this.bookMeta.filter((i) => i.bookId == this.flags.modalItem.id)
        },
    },
    data() {
        return {
            modals: {
                bookExpander: null,
                bookFilters: null,
                customOrder: null,
            },
            toasts: [],
            possibleToasts: null,
            books: [],
            seriesCards: [],
            opts: {
                freq: 0,
                wk: 0,
                lists: [],
                romaji: true,
                tutorial: true,
            },
            flags: {
                optionsInitiated: false, // has done initial options setup in created()
                showingUnreleased: false,
                login: true,
                loaded: false,
                error: false,
                err_msg: '',
                modified: false,
                made: false,
                debug: true,
                modalItem: null,
                showing: 30,
                filterTimeout: null,
                lockrPrefix: 'books_',
                beforeBreadcrumbs: null, // hint for restoring use location
                breadcrumbs: {
                    id: null, // for identification
                    override: null, // important
                    msg: null, // arbitrary
                    val: null,
                    scope: null,
                    func: null,
                }
            },
            bookMeta: [],
            filters: [],
            counts: {
                showing: 0,
                total: 0
            },
            ordering: {
                //  set sort by in created()
                sortBy: 'newest',
                asc: false
            },
            documentedWarnings: [],
            //  items from the <Side /> component that need to be sent elsewhere
            side: {
                search: ['all'],
                mediums: ['b', 'v', 'a', 'o'],
                filters: [],
                ordering: { ordering: "id", orderingAsc: true },
            }
        }
    },
    watch: {
        opts: {
            handler(arr) {
                console.log('%cRunning handler for opts', window.ConsoleStyles.routineConsequence)
                if (!SS.get(SS.PUBLIC_API)) this.saveSettings()
                else {
                    const CONTEXT = 'books'
                    SS.set(SS.USER_SETTINGS_COOKIE, {
                        ...SS.get(SS.USER_SETTINGS_COOKIE) || {},
                        [CONTEXT]: { ...this.opts }
                    })
                }
            },
            deep: true
        },
    },
    methods: {
        showLoginWarning() {
            SendUserAlert('Action failed because user is not logged in', 'alert-warning')
        },
        init(dat) {
            function prepBook(bookItem) {
                // set wtr/fav flags
                if (typeof bookItem.flag === 'string') {
                    bookItem.flag = bookItem.flag.split(',')
                } else {
                    bookItem.flag = []
                }
                // set tags
                // set filter visibility
                bookItem.hidden = false
                //  set word_count
                bookItem.unknown_count = -1
            }
            function handleErr(vm, data) {
                console.warn('err', data.options)
                vm.flags.error = true
                if (typeof data.e === 'string') {
                    vm.flags.err_msg = data.e
                }
            }
            console.log('init received data\n', dat)
            // main
            // error check
            if (dat.items.length <= 0 && dat.msg.toLowerCase().indexOf('error') !== -1) {
                handleErr(this, dat)
                return false
            }
            // modify ajax and push to vue
            const { items } = dat;
            for (let i = 0; i < items.length; i += 1) {
                prepBook(items[i])
                this.books.push(items[i])
            }
            // load modules
            this.loadMetaData(dat)

            //  check if part of series
            const series = this.bookMeta.filter((i) => i.category === 'series')
            if (series.length > 0) {
                const seriesIds = series.map((i) => i.bookId)
                this.books
                    .filter((i) => seriesIds.includes(i.id))
                    .forEach((i) => {
                        const found = series.find((o) => o.bookId === i.id)
                        if (found) {
                            const seriesSplit = found.val.split(';')
                            i.isSeries = parseInt(seriesSplit[1], 10)
                            i.series = seriesSplit[0]
                        }
                    })
            }

            this.flags.loaded = true
            this.counts.total = this.books.length
            this.counts.showing = this.books.length
            // run default ordering now that books are in
            //  this.orderByHandler(this.ordering)
            return true
        },
        loadMetaData(dat) {
            if (!('modules' in dat)) { return false }
            if (!('bookMeta' in dat.modules)) {
                console.warn('No book meta loaded')
                return false
            }
            if ('err' in dat.modules.bookMeta && dat.modules.bookMeta.err) {
                console.warn(dat.modules.bookMeta.err)
                return false
            }
            this.bookMeta = dat.modules.bookMeta.items
            return true
        },
        setModal(id) {
            try {
                this.flags.modalItem = this.books.find((i) => i.id === id)
                this.modals.bookExpander.toggle()
                const activeBook = this.flags.modalItem
                //  this is unnecessary now
                if (!activeBook.unknown_count && activeBook.unknown_count !== 0 && this.flags.login) {
                    this.$set(activeBook, 'unknown_count', 'calculating')
                    this.getUnknown(activeBook)
                }
            } catch (e) {
                SendUserAlert(`Unable to find book information for id ${id}`)
            }
        },
        getAllUnknown() {
            const { books } = this
            if (!this.flags.login) {
                console.log('clearing unkown word storage')
                SS.remove(SS.UNKNOWN_WORD_STORAGE)
                return
            }
            //  if stored in session storage
            const runGetAllUnknown = (dat, bookList) => {
                dat.items.forEach((anItem) => {
                    const found = bookList.find((i) => i.id === anItem.id)
                    if (found) found.unknown_count = anItem.cnt
                })
            }
            //  prioritize allUnknown before dataInStorage (more recent)
            if (!window.allUnknown && SS.get(SS.UNKNOWN_WORD_STORAGE)) {
                console.warn('%cRetrieving unknown words from storage', window.ConsoleStyles.debug)
                runGetAllUnknown(SS.get(SS.UNKNOWN_WORD_STORAGE), books)
                return
            }
            //  full run
            const runAPIAndStore = () => {
                console.log('%cRetrieving unknown words from database', window.ConsoleStyles.debug)
                const apiCall = UseAPI('/get/get-all-unknown', { method: "POST", queryParameters: `wk=${this.opts.wk}` })
                window.allUnknown = apiCall
                return apiCall
            }
            if (this.opts.tutorial) this.toasts.push(this.possibleToasts.loadingWords)
            const theCall = !window.allUnknown ? runAPIAndStore() : window.allUnknown
            theCall
                .then((dat) => {
                    console.warn('attempting to store', dat)
                    SS.set(SS.UNKNOWN_WORD_STORAGE, dat)
                    //  if you navigate away from the page, this will fail but session storage is still set
                    //  so its fine
                    runGetAllUnknown(dat, books)
                    delete window.allUnknown
                })
                .catch((dat) => {
                    HandleRequestFail(dat)
                    console.warn(dat.msg)
                    console.warn(JSON.stringify(dat.e))
                    delete window.allUnknown
                });
        },
        setWK(level) {
            if (!this.flags.login) {
                SendUserAlert('Login required')
                return
            }
            this.opts.wk = level
            SS.remove(SS.UNKNOWN_WORD_STORAGE)
            this.getAllUnknown()
        },
        async getUnknown(item) {
            UseAPI('/get/get-unknown', { method: "POST", queryParameters: `list=${item.vocab_list}` })
                .then((dat) => {
                    this.setUnknown(dat)
                })
                .catch((dat) => {
                    console.warn('error getting books')
                    HandleRequestFail(dat)
                    console.warn(dat.msg)
                    console.warn(JSON.stringify(dat.e))
                });
        },
        setUnknown(dat) {
            try {
                if (!('options' in dat) || !('list' in dat.options)) throw new Error('unexpected response format')
                const { list } = dat.options; const unknown = dat.items[0].cnt
                const arr = this.books.filter((i) => i.vocab_list === list[0])
                arr.forEach((item) => {
                    item.unknown_count = unknown
                })
            } catch (e) {
                console.warn('error setting unknown-count value', e)
            }
        },
        scrollInterface(opts) {
            const { element, block = 'start', delay = false } = opts
            console.warn(opts)
            const action = () => {
                const node = document.getElementById(element)
                if (node) node.scrollIntoView({ behavior: "smooth", block })
                if (!node) console.log(`%cBook ${element} did not load in time`, window.ConsoleStyles.info)
            }
            if (delay) {
                /* would love to use nextTick but it's not working? */
                window.setTimeout(action, 450)
                return
            }
            action()
        },
        modifyFlag(dat) {
            const { id, flag } = dat
            const book = this.books.find((i) => i.id === id)
            if (!this.flags.login) {
                this.showLoginWarning()
                return false
            }
            if (!book) {
                console.warn('unable to find a book with id of', id)
                return false
            }
            let hasFlag = false
            if (book.flag.includes(flag)) hasFlag = true

            if (flag === 'wtr') {
                this.favoriteBook({ id, exists: hasFlag })
            } else if (flag === 'fav') {
                this.readBook({ id, exists: hasFlag })
            }
            return true
        },
        favoriteBook(dat) {
            const { id, exists } = dat
            const found = this.books.find((i) => i.id === id)
            if (!exists) {
                UseAPI('/create/book-flag', {
                    method: "PUT",
                    queryParameters: `flag=wtr&bookId=${id}`
                })
                    .then(() => {
                        ApiPreloader.clearApiKey('/books')
                    })
                if (found) found.flag.push('wtr')
            } else {
                UseAPI('/delete/book-flag', {
                    method: "DELETE",
                    queryParameters: `flag=wtr&bookId=${id}`
                })
                    .then(() => {
                        ApiPreloader.clearApiKey('/books')
                    })
                if (found) found.flag.splice(found.flag.findIndex((i) => i === 'wtr'), 1)
            }
        },
        readBook(dat) {
            //  fav = read (old code)
            const { id, exists } = dat
            const found = this.books.find((i) => i.id === id)
            if (!exists) {
                UseAPI('/create/book-flag', {
                    method: "PUT",
                    queryParameters: `flag=fav&bookId=${id}`
                })
                    .then(() => {
                        ApiPreloader.clearApiKey('/books')
                    })
                if (found) found.flag.push('fav')
            } else {
                UseAPI('/delete/book-flag', {
                    method: "DELETE",
                    queryParameters: `flag=fav&bookId=${id}`
                })
                    .then(() => {
                        ApiPreloader.clearApiKey('/books')
                    })
                if (found) found.flag.splice(found.flag.findIndex((i) => i === 'fav'), 1)
            }
        },
        saveSettings() {
            const body = JSON.stringify({
                context: 'books',
                val: JSON.stringify(this.opts)
            })
            if (this.flags.optionsInitiated) {
                UseAPI('/create/user-settings', {
                    method: "PUT",
                    body
                })
                    .then(() => {
                        SendUserAlert('Settings saved to server', 'alert-success')
                        //  remove cookie and refresh, so that it doesn't hold up future page loads
                        SS.remove(SS.USER_SETTINGS_COOKIE)
                        getSavedOptions()
                    })
                    .catch((result) => {
                        SendUserAlert('Error saving user settings to server', 'alert-danger')
                        console.log('Error saving user settings to server', result)
                    })
            }
        },
        documentWarning(msg) {
            if (!this.documentedWarnings.includes(msg)) {
                this.documentedWarnings.push(msg)
                console.warn(msg)
            }
        }
    },
    mounted() {
        const setupModals = () => {
            const myModal = new Bmodal(document.getElementById('expandBookModal'), {})
            this.modals.bookExpander = myModal
            const modal2 = new Bmodal(document.getElementById('libraryFilterModal'), {})
            this.modals.bookFilters = modal2
            const modal3 = new Bmodal(document.getElementById('expandCustomOrder'), {})
            this.modals.customOrder = modal3
        }
        setupModals()
    },
    created() {
        console.log('%cCreated Books', window.ConsoleStyles.createdComponent, this)

        this.possibleToasts = TOAST_CONFIG
        const tutorialInit = () => {
            if (!this.flags.login) this.toasts.push(this.possibleToasts.notLoggedIn)
            this.toasts.push(this.possibleToasts.findingABook)
            if (this.$mq === 'md+') this.toasts.push(this.possibleToasts.bookClubs)
        }
        const apiOpts = { method: "POST" }
        if (!localStorage.getItem('token')) {
            apiOpts.method = "GET"
            apiOpts.auth = false
            this.flags.login = false
        }

        //  prioritize using preloaded data if possible, if nokey run manually
        ApiPreloader.awaitApiCallFinish('/books', false)
            .then(this.init)
            .catch((res) => {
                console.log('warning', res)
                if (res === 'timeout') {
                    alert('Api request timed out', res)
                } else {
                    console.warn('nokey, so attempting to run manually')
                    const key = "/books"
                    const expiration = 1000 * 60 * 60
                    UseAPI('/get/booksWithFlags', apiOpts)
                        .then((callResult) => ApiPreloader.saveStandardApiCall({ key, expiration, value: callResult }, true))
                        .then(this.init)
                        .catch(HandleRequestFail)
                }
            })
        const CONTEXT = 'books'
        getSavedOptions(CONTEXT)
            .then((opts) => {
                console.log('%cgetUserSettings', window.ConsoleStyles.routine, opts)
                Object.keys(opts).forEach((i) => {
                    //  remove this if eventually
                    if (i !== 'showingUnreleased') this.opts[i] = opts[i]
                })
            })
            .catch((err) => {
                if (!SS.get(SS.PUBLIC_API)) {
                    console.log('getUserSettings', err)
                    SendUserAlert(`Failed to fetch user settings: ${err}`, 'alert-warning')
                }
            })
            .then(() => {
                if (this.opts.tutorial) tutorialInit()
                this.flags.optionsInitiated = true
                this.getAllUnknown()
            })
    }
}
</script>

<style lang="sass">
body
    #bookList
        #side
            i
                padding: 0 .15em
            .module
                @media (max-width: 768px)
                    padding: 1em 5px
                    h2
                        font-size: 1.05em
        div.anim-float:hover
            > .book-card
                top: -10px
        .book-card
            img
                filter: saturate(.9)
            &:hover
                img
                    filter: saturate(1.3)
                    /*filter: blur(0)*/
                /*for hover buttons*/
                .pos-right
                    i
                        cursor: pointer
                        opacity: 1
            i
                border-radius: 75%
            /*for hover buttons*/
            .pos-right
                i
                    opacity: 0
                    transition: .2s ease-out opacity
            .title-outline
                border: solid 2px #eee
                border-width: 0px 0 2px 0
                border-radius: 16px
                h2
                    padding: 0 .3em
                    text-align: center
        .fa-circle.fa-stack-2x + i.fa-stack-1x
            color: white
        .pos-right
            .fa-circle.fa-stack-2x
                /*this is a bootstrap default color for text, so if youre looking for the light version color controls, its not there*/
                color: #212529!important
            .fa-stack-1x
                color: white
    &.dark
        #bookList
            .c-blue
                color: rgb(255, 71, 71)!important
</style>
