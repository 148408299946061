<template>
    <div class="row">
        <BreadcrumbsUI
            class="book-card pad-big marg-big"
            :breadcrumbs="flags.breadcrumbs"
            :resultsNum="titleCount"
            v-on:clicked-close="[$emit('trigger-scroll', {element: beforeBreadcrumbs, block: 'center', delay: true})
            , resetBreadcrumbs()]"
        />
        <CustomBook
            v-if="!cancelSeriesCollapse"
            v-on:open-custom-order-modal="$emit('open-custom-order-modal')"
        />
        <Unreleased
            v-if="!cancelSeriesCollapse && booksUnreleased.length > 0"
            v-for="(items, indx) in booksUnreleased" :key="indx+'a'"
            :class="flags.showingUnreleased ? 'clickable' : 'help'"
            :booksUnreleased="booksUnreleased"
            :flags="flags"
            :items="items"
            :options="{...opts, login: flags.login, ordering, unreleased: true}"
            v-on:change-flag="modifyFlag($event)"
            v-on:cover-click="unreleasedOptions.expanded = true"
            v-on:document-warning="$emit('document-warning', $event)"
            v-on:login-warning="showLoginWarning()"
            v-on:set-modal="$emit('set-modal', $event)"
            v-on:show-related="booksBySeries($event)"
        />
         <Cover v-show="!items.hidden"
            v-for="(items, indx) in booksReleased" :key="indx+'b'"
            class="col-6 col-sm-4 col-lg-3 marg-big anim-float d-flex clickable"
            :cancelSeriesCollapse="cancelSeriesCollapse"
            :items="items"
            :options="{...opts, login: flags.login, ordering}"
            v-on:change-flag="$emit('change-flag', $event)"
            v-on:document-warning="$emit('document-warning', $event)"
            v-on:expand-series="handleExpandSeries($event)"
            v-on:login-warning="$emit('login-warning')"
            v-on:mounted="cardObserver($event)"
            v-on:set-modal="$emit('set-modal', $event)"
            v-on:show-related="$emit('show-related', $event)"
        />
        <div id="infinite-scroll">123</div>
    </div>
</template>

<script>
import { scrollObserver, cardObserver } from './GalleryUtilities'
import TagsJson from '../tags.json'
import GallerySort from './GallerySort'
import CustomBook from './CustomBook.vue'
import Unreleased from './Unreleased.vue'
import Cover from '../Cover/Cover.vue'
import BreadcrumbsUI from '../Breadcrumbs-UI.vue'

const {
 sortById, sortByAlphabetical, sortByUnique, sortByUnknown
} = GallerySort

export default {
    name: 'Gallery',
    components: {
        CustomBook,
        Unreleased,
        Cover,
        BreadcrumbsUI
    },
    props: {
        items: {
            default: () => []
        },
        flags: {
            default: () => ({})
        },
        books: {
            default: () => []
        },
        opts: {
            default: () => ({})
        },
        ordering: {
            default: () => ({})
        },
        searchVal: {
            default: () => ['all']
        },
        activeMediums: {
            default: () => ['a', 'v', 'o', 'b']
        },
        activeFilters: {
            default: () => []
        },
        meta: {
            default: () => []
        },
    },
    data() {
        return {
            unreleasedOptions: {
                expanded: false,
            },
            releasedOptions: {
                progress: 19,
                collapseBySeries: false,
                series: null,
            },
            beforeBreadcrumbs: null,
            anim: {
                card: {
                    transitionTimeVariance: 400,
                    transitionTimeMin: 300,
                    landingDistanceMin: 0,
                    landingDistanceVariance: 4,
                },
            }
        }
    },
    computed: {
        booksUnreleased() {
            const unreleased = []
            this.books.forEach((i) => {
                if (i.until_release) unreleased.push(i)
            })
            let fakeItem = {}
            const unreleasedLen = unreleased.length
            if (unreleasedLen > 0) {
                const numberAsWord = (len) => {
                    switch (len) {
                        case 9:
                            return 'Nine'
                        case 8:
                            return 'Eight'
                        case 7:
                            return 'Seven'
                        case 6:
                            return 'Six'
                        case 5:
                            return 'Five'
                        case 4:
                            return 'Four'
                        case 3:
                            return 'Three'
                        case 2:
                            return 'Two'
                        case 1:
                            return 'One'
                        case 0:
                            return 'Zero'
                        default:
                            return len
                    }
                }
                fakeItem = {
                    ...unreleased[0],
                    title: `${numberAsWord(unreleasedLen)} Coming Soon`,
                    title_jp: `${numberAsWord(unreleasedLen)} Coming Soon`,
                }
            }
            if (this.unreleasedOptions.expanded) return unreleased
            if (unreleased.length > 0) return [fakeItem]
            return []
        },
        booksReleased() {
            /* Features
            * X Infinite Scroll (mount/beforeDestroy onScroll)
            * X Sort (bookReleasedSorted)
            * X Search (booksSearchedFor)
            * X Released vs Unreleased
            * X Partial Load (releaedOptions.progress)
            * X Filter by Medium (booksByMedium)
            * X Filter by Filter (booksByFilter)
            * X Filter by Series (booksBySeries)
            * O Act: Scroll Back
            * O Act: View Series
            * O Act: Open Modal (Series)
            * O Act: Open Modal (Single)
            */
            const released = []
            this.books.forEach((i) => {
                if (!i.until_release) released.push(i)
            })
            return (
                this.booksSortedBy(
                    this.booksCollapsedBySeries(
                    this.booksByMedium(
                    this.booksByFilter(
                    this.booksBySeries(
                        this.booksSearchedFor(released, this.searchVal)
                    )
                    )
                    )
                    )
                )
                .slice(0, this.releasedOptions.progress)
            )
        },
        cancelSeriesCollapse() {
            if (this.releasedOptions.series) return true
            if (this.searchVal[0] !== 'all') return true
            if (this.activeFilters.length !== 0) return true
            return false
        },
        titleCount() {
            return this.booksReleased.length + this.booksUnreleased.length
        }
    },
    methods: {
        test(str) {
            window.alert(str)
        },
        /* **********
        * Methods
        *********** */
        resetBreadcrumbs() {
            //  breadcrumbs are set by methods manually changing what's being shown (like toggleSeriesView)
            const breadcrumbsDef = {
                id: null, override: null, msg: null, val: null, scope: null, func: null,
            }
            this.flags.breadcrumbs = { ...breadcrumbsDef }
            this.releasedOptions.series = null
            this.beforeBreadcrumbs = null
        },
        handleExpandSeries(opts) {
            const { series, element } = opts
            this.releasedOptions.series = series
            /* set breadcrumbs */
            this.beforeBreadcrumbs = element
            this.$emit('expand-series', {
                msg: { title: series, text: null }
            })
            this.$emit('trigger-scroll', { element: 'websiteHeader' })
        },
        /* *************
         * Book Display
         ************* */
        booksToShow() {
            const unreleased = []
            const released = []
            this.books.forEach((i) => {
                i.until_release ? unreleased.push(i) : released.push(i)
            })
            return {
                unreleased,
                released
            }
        },
        booksSearchedFor(arr, idList = [415, 416, 418]) {
            //  get the list of ids from <Search /> somehow
            //  then check if ids exist within
            if (idList.length === 0) return []
            if (idList[0] === 'all') return arr
            return arr
                    .filter((i) => idList.includes(i.id))
        },
        booksSortedBy(arr) {
            const { ordering, orderingAsc } = this.ordering
            const unknownCheck = () => arr[0].unknown_count === -1
            switch (ordering) {
                case 'id':
                    this.sortById(arr, orderingAsc)
                    break;
                case 'unknown':
                    if (unknownCheck()) {
                        alert('Attempted to sort by unknown words before it\'s loaded. Sort will begin when finished.')
                        const setIntervalSortByUnknown = (arg1, arg2) => {
                            if (!unknownCheck()) {
                                this.sortByUnknown(arg1, arg2)
                                window.clearTimeout(window.librarySetInterval)
                            }
                        }
                        window.librarySetInterval = window.setInterval(setIntervalSortByUnknown, 200, arr, orderingAsc)
                    } else {
                        this.sortByUnknown(arr, orderingAsc)
                    }
                    break;
                case 'unique':
                    this.sortByUnique(arr, orderingAsc)
                    break;
                case 'alphabetical':
                    this.sortByAlphabetical(arr, orderingAsc)
                    break;
                default:
                    this.sortById(arr, true)
            }
            return arr
        },
        booksBySeries(arr) {
            const series = this.releasedOptions.series
            if (!series) return arr
            return arr
                    .filter((i) => i.series === series)
        },
        booksByMedium(arr) {
            return arr
                    .filter((i) => this.activeMediums.includes(i.medium))
        },
        booksByFilter(arr) {
            if (this.activeFilters.length === 0) return arr
            let useOwned = false
            let useFree = false
            let useClub = false
            let useEasy = false
            this.activeFilters.forEach((i) => {
                if (i === 'owned') useOwned = true
                if (i === 'free') useFree = true
                if (i === 'club') useClub = true
                if (i === 'easy') useEasy = true
            })
            return arr
                    .filter((i) => {
                        let found = false
                        //  wtr, fav
                        i.flag.forEach((o) => {
                            if (this.activeFilters.includes(o)) found = true
                        })
                        if (useOwned && i.permission !== 'public') found = true
                        if (useFree && TagsJson.free.includes(i.id)) found = true
                        if (useEasy && TagsJson.children.includes(i.id)) found = true
                        if (useClub && TagsJson.bookClub.includes(i.id)) found = true
                        return found
                    })
        },
        booksCollapsedBySeries(arr) {
            if (this.cancelSeriesCollapse) return arr
            const series = this.series(arr)
            const getNotSeriesBooks = () => arr.filter((i) => !i.series)
            const getSeriesBooks = () => {
                const seriesList = Object.keys(series)
                const releasedIdsMap = seriesList.map((key) => {
                    const item = series[key]
                    return item.releasedId
                })
                return arr.filter((i) => releasedIdsMap.includes(i.id))
            }
            arr.filter((i) => i.id === 532).forEach((i) => {
                console.log(`!i.series ${!i.series}`)
            })
            return [...getNotSeriesBooks(), ...getSeriesBooks()]
        },
        series(arr) {
            /* Track what series exist and:
            highest volumes (released, unreleased)
            highest volume ids
            */
            const list = {}
            const listTemp = []
            arr.forEach((i) => {
                if (!i.series) return
                if (!listTemp.includes(i.series)) {
                    listTemp.push(i.series)
                    list[i.series] = {
                        unreleased: 0,
                        released: 0,
                        unreleasedId: null,
                        releasedId: null
                    }
                }
                const relevantListItem = list[i.series]
                if (i.until_release && relevantListItem.unreleased < i.isSeries) {
                    relevantListItem.unreleased = i.isSeries
                    relevantListItem.unreleasedId = i.id
                } else if (!i.until_release && relevantListItem.released < i.isSeries) {
                    relevantListItem.released = i.isSeries
                    relevantListItem.releasedId = i.id
                }
            })
            return list
        },
        sortById,
        sortByAlphabetical,
        sortByUnique,
        sortByUnknown,
        loadMoreBooks() {
            this.releasedOptions.progress += 12
        },
        scrollObserver,
        cardObserver,
    },
    watch: {
    },
    created() {
        console.log('%cCreated Gallery', window.ConsoleStyles.createdComponent, this)
    },
    mounted() {
        const vm = this
        this.$nextTick(() => {
            this.scrollObserver('infinite-scroll')
        })
    },
    beforeDestroy() {
    }
}
</script>

<style lang="scss">
    .anim-fade-in {
        --transition-time: 200ms;
        opacity: 0;
        position: relative;
        top: 25%;
        transition: all var(--transition-time) ease-out;
    }
    .anim-fade-in.anim-start {
        --landing: 0;
        opacity: 1;
        top: var(--landing);
    }
    #infinite-scroll {
        opacity: 0;
    }
</style>
