export function scrollObserver(id) {
    const obsOptions = {
        threshold: 0,
        rootMargin: "0px 450px",
    }
    const act = (entries, observer) => {
        entries.forEach((i) => {
            if (i.isIntersecting) {
                this.loadMoreBooks()
                observer.unobserve(i.target)
                window.setTimeout(() => {
                    const obs = new IntersectionObserver(act, obsOptions)
                    obs.observe(document.getElementById(id))
                }, 1000)
            }
        })
    }
    const obs = new IntersectionObserver(act, obsOptions)
    obs.observe(document.getElementById(id))
}
export function cardObserver(id) {
    const element = document.getElementById(id)
    if (!element) return
    const {
        landingDistanceMin, landingDistanceVariance, transitionTimeVariance, transitionTimeMin
    } = this.anim.card
    const obsOptions = {
        root: null,
        threshold: 0.65,
    }
    const obs = new IntersectionObserver((entries, observer) => {
        entries.forEach((i) => {
            if (i.isIntersecting) {
                i.target.classList.add('anim-start')
                observer.unobserve(i.target)
                setTimeout(() => {
                    i.target.style.removeProperty('--transition-time')
                    i.target.style.removeProperty('pointer-events')
                }, 1000)
            }
        })
    }, obsOptions)
    obs.observe(element)
    // set init state
    element.classList.add('anim-fade-in')
    element.style.setProperty(
        '--transition-time',
        `${Math.floor(Math.random() * transitionTimeVariance) + transitionTimeMin}ms`
    )
    element.style.setProperty(
        '--landing',
        `${Math.floor(Math.random() * landingDistanceVariance) + landingDistanceMin}%`
    )
    element.style.setProperty('pointer-events', 'none') // stop hover from destroying animation
}
