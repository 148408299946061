<template>
    <div v-if="breadcrumbs.msg">
        <div class="d-flex breadcrumb-heading">
            <h2>{{breadcrumbs.msg.title}}</h2>
            <div class="breadcrumbs-close">
                <span class="badge bg-jet clickable fr" @click="$emit('clicked-close')">
                    <i class="fas fa-times"></i>
                </span>
            </div>
        </div>
        <div>
            <span class="badge bg-info fl">
                <i class="fas fa-tag"></i> {{resultsNum}}
            </span>
            <div v-if="breadcrumbs.msg.text">{{breadcrumbs.msg.text}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BreadcrumbsUI',
    props: ['breadcrumbs', 'resultsNum'],
}
</script>

<style lang="sass" scoped>
.book-card
    display: inline-block
.breadcrumb-heading
    justify-content: space-between
.fl
    float: left
.breadcrumbs-close
    padding: 0 .5em 0 1.5em
</style>
