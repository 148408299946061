<template>
<div class="modal fade" id="expandCustomOrder" tabindex="-1" role="dialog" aria-labelledby="expandCustomOrder" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <!-- landing -->
            <div
                v-if="tab === 'landing'"
                class="modal-header"
            >
                <h2>Custom Orders</h2>
            </div>
            <div
                v-if="tab === 'landing'"
                class="modal-body"
            >
                If you'd like a list put up for your personal use, then you can order one.<br>
            </div>
            <div
                v-if="tab === 'landing'"
                class="modal-header"
            >
                <h2 style="color: orange;">Pricing Preview</h2>
            </div>
            <Preview
                v-if="tab === 'landing'"
            />
            <!-- order -->
            <div
                v-if="tab === 'order'"
                class="modal-header"
            >
                <h2>Order Process</h2>
            </div>
            <OrderProcess
                v-if="tab === 'order'"
            />
            <div
                v-if="tab === 'order'"
                class="modal-header"
            >
                <h2>Additional Details</h2>
            </div>
            <AdditionalDetails
                v-if="tab === 'order'"
            />
            <!-- cta -->
            <button
                class="order-cta"
                @click="tab === 'order' ? tab = 'landing' : tab = 'order'"
            >{{tab === 'order' ? 'Back' : 'See Order Process'}}</button>
        </div>
    </div>
</div>
</template>

<script>
import Preview from './Preview.vue'
import OrderProcess from './OrderProcess.vue'
import AdditionalDetails from './AdditionalDetails.vue'
/* import Contact from './Contact.vue' */

export default {
    name: 'CustomOrder',
    components: {
        Preview,
        OrderProcess,
        AdditionalDetails,
        /* Contact, */
    },
    data() {
        return {
            tab: 'landing'
        }
    }
}
</script>

<style lang="sass">
body.dark
    table
        input
            color: black!important
            font-size: 1.2rem
.order-cta
    color: orange
    border: 3px solid orange
    border-radius: 2px
    min-width: 10em
    text-align: center
    padding: 1em 2.5em
    margin: 1em auto
    background-color: transparent
    font-weight: bold
    letter-spacing: .1em
    font-family: 'Poppins', sans-serif
    transition: .25s all
.order-cta:focus, .order-cta:hover
    background-color: orange
    color: white
</style>
