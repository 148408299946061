<template>
    <Cover
        v-show="!items.hidden" v-if="booksUnreleased.length > 0"
        :class="flags.showingUnreleased ? 'clickable' : 'help'"
        class="col-6 col-sm-4 col-lg-3 marg-big anim-float d-flex"
        :items="items"
        :options="options"
        :unreleased="true"
        :cancelSeriesCollapse="cancelSeriesCollapse"
        v-on:cover-click="$emit('cover-click')"
        v-on:login-warning="$emit('login-warning')"
        v-on:change-flag="$emit('change-flag', $event)"
        v-on:show-related="$emit('show-related', $event)"
    />
</template>

<script>
import Cover from '../Cover/Cover.vue'

export default {
    name: 'Unreleased',
    components: {
        Cover
    },
    props: [
        'flags',
        'booksUnreleased',
        'items',
        'options',
        'cancelSeriesCollapse'
    ]
}
</script>
