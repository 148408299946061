const GallerySort = {
    sortById(arr, byAsc) {
        const success = byAsc ? 1 : -1
        const compare = (a, b) => {
            if (a.id > b.id) return success * -1
            if (a.id < b.id) return success
            return 0
        }
        return arr.sort(compare)
    },
    sortByUnique(arr, byAsc) {
        const success = byAsc ? 1 : -1
        const compare = (a, b) => {
            if (a.word_count > b.word_count) return success * -1
            if (a.word_count < b.word_count) return success
            return 0
        }
        return arr.sort(compare)
    },
    sortByUnknown(arr, byAsc) {
        const success = byAsc ? 1 : -1
        const calcPercentageKnown = (item) => (
            ((item.word_count - item.unknown_count) / item.word_count) * 100
        )
        const compare = (a, b) => {
            const aCalc = calcPercentageKnown(a)
            const bCalc = calcPercentageKnown(b)
            if (aCalc > bCalc) return success * -1
            if (aCalc < bCalc) return success
            return 0
        }
        return arr.sort(compare)
    },
    sortByAlphabetical(arr, byAsc) {
        /* const success = byAsc ? 1 : -1 */
        const success = byAsc ? -1 : 1
        const compare = (a, b) => {
            if (a.title > b.title) return success * -1
            if (a.title < b.title) return success
            return 0
        }
        return arr.sort(compare)
    },
}

export default GallerySort
