<template>
    <div class="modal-footer" style="justify-content: flex-start">
        <div class="row w-100">
            <div v-if="meta.length > 0"  class="col external-links px-0">
                <div class="d-flex clickable no-padding"
                    :class="indx !== 0 ? 'px-2' : ''"
                    v-for="(items, indx) in metaToList"
                    v-tippy
                    :content="items.description"
                    :key="indx"
                >
                    <a :href="items.val" target="_blank" class="d-flex" style="align-items:center; flex-direction: column">
                        <div>
                            <span class="fa-stack">
                                <i class="fa-stack-2x fas fa-square"></i>
                                <i class="fas fa-stack-1x" :class="items.icon"></i>
                            </span>
                        </div>
                        <div>
                            <span class="badge bg-jet">
                                {{items.host}}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
            <div v-else class="col external-links px-0">
                <p>No links available</p>
            </div>
            <div class="col actions px-0">
                <div class="d-flex flex-column align-items-center" style="min-width: 4em"
                v-if="loggedIn"
                >
                    <div class="fa-stack fa d-block clickable" v-tippy content="Want to read"
                    @click.stop.prevent="handleFlagChange({id: item.id, flag: 'wtr'})"
                    >
                        <i class="fa fa-square fa-stack-2x icon-background"></i>
                        <i class="fa fa-heart fa-stack-1x" :class="item.flag.includes('wtr') ? 'c-blue' : 'c-white'"></i>
                    </div>
                    <div><span class="badge bg-jet">wtr</span></div>
                </div>
                <div class="d-flex flex-column align-items-center" style="min-width: 4em"
                v-if="loggedIn"
                >
                    <div class="fa-stack fa d-block clickable" v-tippy content="Already read"
                        @click.stop.prevent="handleFlagChange({id: item.id, flag: 'fav'})"
                    >
                        <i class="fa fa-square fa-stack-2x icon-background"></i>
                        <i class="fa fa-book fa-stack-1x" :class="item.flag.includes('fav') ? 'c-blue' : 'c-white'"></i>
                    </div>
                    <div><span class="badge bg-jet">read</span></div>
                </div>
                <div v-if="!item.until_release" class="d-flex flex-column align-items-center" style="min-width: 4em"
                >
                    <div
                        class="fa-stack fa d-block clickable"
                        v-tippy
                        content="Go to vocab list"
                    >
                        <router-link :to="{ path: '/list', query: { list: item.vocab_list } }" data-dismiss="modal">
                            <i class="fa fa-square fa-stack-2x icon-background"></i>
                            <i class="fa fa-link fa-stack-1x c-blue a-glow"></i>
                        </router-link>
                    </div>
                    <div><span class="badge bg-jet">vocab list</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SS } from '@/assets/constants'

export default {
    name: 'ModalLinks',
    data() {
        return {}
    },
    methods: {
        handleFlagChange(opts) {
            const { id, flag } = opts
            return this.$emit('flag-change', { id, flag })
        }
    },
    props: ['meta', 'metaToList', 'item'],
    computed: {
        loggedIn() {
            return !SS.get(SS.PUBLIC_API)
        }
    }
}
</script>

<style lang="sass" scoped>
.external-links
    display: flex
    justify-content: flex-start
.actions
    display: flex
    justify-content: flex-end
</style>
