<template>
<div>
    <mq-layout mq="lg+">
        <h2>Ordering</h2>
    </mq-layout>
    <mq-layout :mq="['xs', 'sm', 'md']">
        <div class="modal-header"><h2>Ordering</h2></div>
    </mq-layout>
    <div
        class="d-flex flex-wrap"
        :class="bodyLayout"
    >
        <div>
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-calendar-alt"
                :colors="{outer: 'c-blue'}"
                v-tippy
                :content="'Order by release date'"
                :activated="id"
                @click="changeValue('id')"
            />
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-skull"
                :colors="{outer: 'c-blue'}"
                v-tippy
                :content="'Order by difficulty (unique words)'"
                :activated="unique"
                @click="changeValue('unique')"
            />
        </div>
        <div>
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-font"
                :colors="{outer: 'c-blue'}"
                v-tippy
                :content="'Order by English title'"
                :activated="alphabetical"
                @click="changeValue('alphabetical')"
            />
            <BLabel
                class="clickable"
                shape="fa-square"
                icon="fa-question"
                :colors="{outer: 'c-blue'}"
                v-tippy
                :content="'Order by unknown words'"
                :activated="unknown"
                @click="changeValue('unknown')"
            />
        </div>
        <BLabel
            class="clickable"
            shape="fa-square"
            :icon="asc ? 'fa-sort-amount-up' : 'fa-sort-amount-down'"
            :colors="{outer: asc ? 'c-blue' : 'c-red'}"
            v-tippy
            :content="asc ? 'Ascending order' : 'Descending order'"
            :activated="true"
            @click="changeValue('asc')"
        />
    </div>
</div>
</template>

<script>
import BLabel from '../../Shared/ButtonWithLabel.vue'

export default {
    name: 'Mediums',
    props: ['books'],
    data() {
        return {
            id: true,
            alphabetical: false,
            unique: false,
            unknown: false,
            asc: true,
        }
    },
    components: {
        BLabel
    },
    computed: {
        bodyLayout() {
            return ['xxl', 'xl', 'lg'].includes(this.$mq)
                    ? 'justify-content-center'
                    : 'modal-body'
        }
    },
    methods: {
        changeValue(prop) {
            if (prop !== 'asc') {
                this.id = false
                this.alphabetical = false
                this.unique = false
                this.unknown = false
                this[prop] = true
                return
            }
            this.asc = !this.asc
        },
        getResults() {
            if (this.id) return { ordering: 'id', orderingAsc: this.asc }
            if (this.alphabetical) return { ordering: 'alphabetical', orderingAsc: this.asc }
            if (this.unique) return { ordering: 'unique', orderingAsc: this.asc }
            if (this.unknown) return { ordering: 'unknown', orderingAsc: this.asc }
            return { ordering: 'id', orderingAsc: this.asc }
        },
        syncResults() {
            this.$emit('changed-active-ordering', this.getResults())
        }
    },
    watch: {
        id() {
            this.syncResults()
        },
        alphabetical() {
            this.syncResults()
        },
        unique() {
            this.syncResults()
        },
        unknown() {
            this.syncResults()
        },
        asc() {
            this.syncResults()
        }
    }
}
</script>
