<template>
<div class="modal-body">
    <div class="container-fluid">
        <table class="table">
            <thead>
                <tr>
                    <td>Units</td>
                    <td>Price</td>
                    <td>Total</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>25</td>
                    <td><b style="color: orange;">$25</b></td>
                </tr>
                <tr>
                    <td><input v-model="bulk.quantity" type="number" min="1" max="99" value="2" style="line-height:2; min-width: 12.5em;"><br>Breakpoints: 2, 3, 6</td>
                    <td>{{bulk.quantity}} * {{bulkOrder.perUnit}}</td>
                    <td><b style="color: orange;">${{bulkOrder.total}}</b></td>
                </tr>
                <tr>
                    <td>
                        Sale price:<br>
                        <input v-model="bulk.quantity" type="number" min="1" max="99" value="2" style="line-height:2; min-width: 12.5em;">
                        <br>Sale dates: <b>every month 1st-7th</b>
                        <br><span v-html="checkDate"></span>
                    </td>
                    <td>{{bulk.quantity}} *  {{bulkOrder.perUnit * sale}}</td>
                    <td><b>
                        <s>${{bulkOrder.total}}</s><br>
                        <span style="color: orange;">${{bulkOrder.onSale.toFixed(2)}}</span>
                    </b></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
    name: "Preview",
    data() {
        return {
            bulk: {
                quantity: 3,
                quantityMap: [1, 2, 3, 6, 99],
                perUnit: [25.00, 22.50, 20.00, 18.50, 18.50],
            },
            saleDate: false,
            sale: 0.85,
        }
    },
    computed: {
        bulkOrder() {
            const exact = this.bulk.quantityMap.findIndex((i) => i === this.bulk.quantity)
            const tooHigh = this.bulk.quantityMap.findIndex((i) => i > this.bulk.quantity)
            const unitCost = this.bulk.perUnit[exact !== -1 ? exact : tooHigh - 1]
            /* console.log(`%c${exact}, ${tooHigh}, ${unitCost}`, window.ConsoleStyles.debug) */
            return {
                perUnit: unitCost,
                total: unitCost * this.bulk.quantity,
                onSale: unitCost * this.bulk.quantity * this.sale
            }
        },
        checkDate() {
            if (this.saleDate) return '<span class="badge bg-success">Sale Active</span>'
            return '<span class="badge bg-jet">Sale Not Active</span>'
        }
    },
    created() {
        const curDate = new Date()
        const curDateDate = curDate.getDate()
        if (curDateDate >= 1 && curDateDate <= 7) this.saleDate = true
    }
}
</script>
