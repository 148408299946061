<template>
    <div>
        <div>
            <h2>{{title}}</h2>
            <span class="marg-small d-block" lang="ja">{{title_jp}}</span>
        </div>
        <div>
            <span class="fa-stack clickable" data-bs-dismiss="modal" aria-lable="Close">
                <i class="fas fa-square fa-stack-2x"></i>
                <i class="fas fa-times fa-stack-1x"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalHeader',
    data() {
        return {}
    },
    props: ['title', 'title_jp']
}
</script>
