<template>
    <div class="modal fade" id="libraryFilterModal" tabindex="-1" role="dialog" aria-labelledby="libraryFilterModal" aria-hidden="true"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        >
            <div class="modal-content" style="min-height: 15em;">
                <div class="modal-header">
                    <h2>Options</h2>
                    <span class="fa-stack clickable" data-bs-dismiss="modal" aria-lable="Close">
                        <i class="fas fa-square fa-stack-2x"></i>
                        <i class="fas fa-times fa-stack-1x"></i>
                    </span>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <div class="align-center px-2 clickable" @click="toggleRomaji()">
                            <div class="fa-stack">
                                <i class="fa-stack-2x fas fa-square"></i>
                                <i class="fa-stack-1x fas c-white" :class="opts.romaji ? 'fa-globe-americas' : 'fa-globe-asia'"></i>
                            </div>
                            <div>
                                <span class="badge bg-jet">{{opts.romaji ? 'romaji titles' : 'japanese titles'}}</span>
                            </div>
                        </div>
                        <div class="align-center px-2 clickable" @click="$emit('toggle-tutorial')">
                            <div class="fa-stack" v-tippy content="Show tutorial messages">
                                <i class="fa-stack-2x fas fa-square"></i>
                                <i class="fa-stack-1x fas c-white fa-question"></i>
                            </div>
                            <div>
                                <span class="badge bg-jet">{{opts.tutorial ? 'hints enabled' : 'hints disabled'}}</span>
                            </div>
                        </div>
                        <div class="align-center px-2 clickable" @click="setWK()">
                            <div class="fa-stack" v-tippy content="adjust wanikani levels">
                                <i class="fa-stack-2x fas fa-square"></i>
                                <i class="fa-stack-1x fas c-white fa-trophy"></i>
                            </div>
                            <div>
                                <span class="badge bg-jet">{{`WK level ${opts.wk}`}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <mq-layout :mq="['xs', 'sm', 'md']">
                    <Ordering
                    v-on:changed-active-ordering="$emit('changed-active-ordering', $event)"
                    />
                </mq-layout>
            </div>
        </div>
    </div>
</template>

<script>
import Ordering from './Side/Ordering.vue'

export default {
    name: "Options",
    props: [
        'ordering',
        'opts'
    ],
    components: {
        Ordering,
    },
    methods: {
        setWK() {
            const level = prompt('Set Wanikani level', 0)
            if (!isNaN(level) && level) {
                this.$emit('set-wk', parseInt(level, 10))
            }
        },
        toggleRomaji() {
            this.$emit('toggle-romaji')
        },
    },
}
</script>
