<template>
<div class="modal-body">
    <ul>
        <li>List is for personal use</li>
        <li>Refunds only if I physically cannot create the list</li>
        <li>If you want a <b>manga</b> or <b>visual novel</b> you will need to provide a transcript. Some visual novel transcripts can be found <a href="https://vnscripts.neocities.org/">here.</a></li>
        <li>Most <b>anime</b> and <b>books</b> are OK (we'll need to confirm though).</li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'AdditionalDetails'
}
</script>
