<template>
    <div class="modal-body">
    <p v-if="bookDesc"
    style="white-space:pre-line;"
    :lang="(bookDesc && bookDesc.search(/[あえいおうが]/) !== -1 ) ? 'jp' : 'en'"
    >
        {{ bookDesc }}
    </p>
    <p v-else>No description found</p>
    </div>
</template>

<script>
export default {
    name: 'ModalBody',
    data() {
        return {}
    },
    props: ['bookDesc']
}
</script>
