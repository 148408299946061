<template>
    <div class="top-badges">
        <div>
            <span
                v-if="isOngoing"
                class="badge bg-success"
                v-tippy="{ placement: 'right' }"
                content="Series was not completed at time of list creation"
            >ongoing</span>
            <span
                class="badge"
                :class="readabilityScore.badge"
                v-tippy="{ placement: 'right' }"
                content="Percentage of known words"
                v-if="readabilityScore"
            >
                <span class="text-dark">{{`${readabilityScore.num.toFixed(1)}%`}}</span>
                <span class="text-dark" v-html="readabilityScore.skulls"></span>
            </span>
        </div>
        <div>
            <span
                class="badge bg-info"
                v-tippy="{ placement: 'right' }"
                content="Part of a series"
                v-if="items.isSeries"
                @click.stop.prevent="$emit('show-related', items.id)"
            >
                <span class="text-dark">{{`Vol. ${items.isSeries} | `}}</span>
                <span class="text-dark"><i class='fas fa-folder-open text-dark'></i></span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoverBadges',
    props: ['items'],
    computed: {
        readabilityScore() {
            const { items } = this
            if (items.unknown_count === -1) return null
            const knownWords = items.word_count - items.unknown_count
            const percentageKnown = (knownWords / items.word_count) * 100
            const cutoffs = { easy: 79, medium: 69, hard: 49 }
            const determineBadge = () => {
                if (items.unknown_count < 500 || percentageKnown > cutoffs.easy) return 'bg-success'
                if (items.unknown_count < 1500 || percentageKnown > cutoffs.medium) return 'bg-warning'
                return 'bg-danger'
            }
            const determineSkulls = () => {
                if (items.unknown_count < 500 || percentageKnown > cutoffs.easy) return " | <i class='fas fa-skull text-dark'></i>"
                if (items.unknown_count < 1500 || percentageKnown > cutoffs.medium) return " | <i class='fas fa-skull text-dark'></i> <i class='fas fa-skull text-dark'></i>"
                return " | <i class='fas fa-skull text-dark'></i> <i class='fas fa-skull text-dark'></i> <i class='fas fa-skull text-dark'></i>"
            }
            return {
                num: percentageKnown > 0 ? percentageKnown : 0.0,
                badge: determineBadge(),
                skulls: determineSkulls()
            }
        },
        isOngoing() {
            if (
                this.items.medium === 'a'
                && this.items.title.toLowerCase().search('ongoing') !== -1
            ) return true
            return false
        }
    }
}
</script>

<style lang="sass">
.top-badges
    .bg-danger
        border-bottom: 2px solid #922630
    .bg-info
        border-bottom: 2px solid #263d92
    .bg-warning
        border-bottom: 2px solid #926726
    .bg-success
        border-bottom: 2px solid #265b92
</style>
