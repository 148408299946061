<template>
<div @click="$emit('click')">
    <div :class="`fa-stack fa buttons ${activated ? 'clickable' : ''}`"
    >
        <i class="fa fa-stack-2x icon-background"
        :class="`${activated ? outerColor : ''} ${shape} `"
        ></i>
        <i class="fa fa-stack-1x"
            :class="`${icon} ${activated ? innerColor : ''}`"
        ></i>
        <slot name="counter"></slot>
    </div>
    <div v-if="shownLabel">
        <span :class="`badge ${activated ? labelColor : 'bg-jet'}`">{{shownLabel}}</span>
    </div>
</div>
</template>

<script>
//  :class="words.some( (i)=>i.selected==true ) ? 'anim-shimmer': '' "

export default {
    name: 'BLabel',
    created() {
        if (this.debug) console.log('%cCreated Button With Label', window.ConsoleStyles.createdComponent, this)
    },
    computed: {
        shownLabel() {
            return this.label || false
        },
        innerColor() {
            const { inner } = this.colors
            return inner || ''
        },
        labelColor() {
            const { text } = this.colors
            return text || 'bg-secondary'
        },
        outerColor() {
            const { outer } = this.colors
            return outer || ''
        }
    },
    props: {
        words: {},
        activated: {
            default() {
                return true
            }
        },
        colors: {
            default() {
                //  available: inner, text, outer
                return {}
            }
        },
        shape: {
            default() {
                return 'fa-square'
            }
        },
        icon: {
            default() {
                return 'fa-check'
            }
        },
        label: {
            default() {
                return false
            }
        }
    },
    data() {
        return {
            debug: false
        }
    }
}
</script>
