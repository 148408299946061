<template>
    <div class="modal fade" id="expandBookModal" tabindex="-1" role="dialog" aria-labelledby="expandBookModal"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div v-if="item" class="modal-content split-modal">
                <!-- l -->
                <div class="desktop-only modal-header" style="align-items: baseline">
                    <ModalImage :items="item" />
                </div>
                <!-- r -->
                <div class="split-modal-right">
                    <ModalHeader class="modal-header" style="align-items: baseline" :title="item.title"
                        :title_jp="item.title_jp" />
                    <ModalBody :bookDesc="bookDesc" />
                    <ModalStats :item="item" :series="series" :aired="aired" />
                    <ModalLinks :meta="meta" :metaToList="metaToList" :item="item"
                        v-on:login-warning="$emit('login-warning')" v-on:flag-change="$emit('flag-change', $event)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import ModalHeader from './Modal-Header.vue'
import ModalBody from './Modal-Body.vue'
import ModalImage from './Modal-Image.vue'
import ModalLinks from './Modal-Links.vue'
import ModalStats from './Modal-Stats.vue'

const {
    HandleRequestFail, UseAPI
} = C

export default {
    name: 'Modal',
    props: [
        'login',
        'item',
        'meta',
    ],
    components: {
        ModalHeader,
        ModalBody,
        ModalImage,
        ModalLinks,
        ModalStats,
    },
    data() {
        return {
            flags: {
                showingBody: true
            }
        }
    },
    created() {
        console.log('%cCreated modal', window.ConsoleStyles.createdComponent, this)
    },
    computed: {
        bookDesc() {
            const x = this.meta.find((i) => i.category === 'desc')
            if (x) return x.val.trim()
            return x
        },
        series() {
            const found = this.meta.find((i) => i.category === 'series')
            console.log('%cModal: Active Book Meta', window.ConsoleStyles.info, found)
            if (!found) return null
            const series = found.val.split(';')
            return {
                series: series[0],
                volume: series[1]
            }
        },
        aired() {
            const found = this.meta.find((i) => i.category === 'aired')
            if (!found) return null
            return found
        },
        metaToList() {
            const { meta } = this
            const categoriesToShow = ['info', 'buy', 'discussion']
            const icons = {
                info: 'fa-info',
                buy: 'fa-shopping-cart',
                discussion: 'fa-comments'
            }
            return meta
                .filter((i) => categoriesToShow
                    .includes(i.category))
                .map((i) => ({ ...this.infoDump(i), icon: icons[i.category] }))
        }
    },
    methods: {
        goToListPage() {
            const bookList = this.item.vocab_list;
            this.$router.push({ path: '/list', query: { list: bookList } })
            /* $('#expandBookModal').modal('hide') */
        },
        infoDump(item) {
            // for construction of an object to handle the info found in an 'info' category book meta

            const extractHostname = (url) => {
                let hostname;
                // find & remove protocol (http, ftp, etc.) and get hostname
                if (url.indexOf("//") > -1) {
                    // eslint-disable-next-line prefer-destructuring
                    hostname = url.split('/')[2];
                } else {
                    // eslint-disable-next-line prefer-destructuring
                    hostname = url.split('/')[0];
                }
                // find & remove port number
                // eslint-disable-next-line prefer-destructuring
                hostname = hostname.split(':')[0];
                // find & remove "?"
                // eslint-disable-next-line prefer-destructuring
                hostname = hostname.split('?')[0];

                return hostname.replace(/www\./, '');
            }

            const setDescription = (host) => {
                if (host.search('myanimelist') !== -1) return 'My anime list is a community and database of anime information'
                if (host.search('because') !== -1) return 'Because.moe is a database that uses API\'s to find out where you can watch anime for the US/CA/UK/AU regions'
                if (host.search('vndb') !== -1) return 'The Visual Novel Database hosts information on various Japanese games including where you can purchase them'
                if (host.search('amazon') !== -1) return 'This is a referral link, so if you\'d like to support the website purchase through here. Alternatively, just donate if you\'d like to support and don\'t like Amazon.'
                return ''
            }

            const modifyVal = (host, val) => {
                //  strip then add affiliate tag if amazon
                if (host.search('amazon') >= 0) return `${val.replace(/\?(.*?)$/, '')}?tag=koohi-22`
                //  strip if not because.moe
                if (host.search('because.moe') === -1) return val.replace(/\?(.*?)$/, '')
                //  if because .moe, leave query params intact
                return val
            }

            const host = extractHostname(item.val)
            const { category } = item
            const val = modifyVal(host, item.val)
            const description = setDescription(host)
            // change val depending on factors
            /* val = host.search('because.moe') !== -1 ? val : val.replace(/\?(.*?)$/, '') */

            return {
                category,
                val,
                host,
                description
            }
        },
    }
}
</script>

<style lang="sass" scoped>
.badge
    margin-right: .25em
.modal-body
    max-height: 350px
    overflow-y: auto
    overflow-x: hidden
.modal-header
    span[lang="ja"]
        font-size: 90%
.col.no-padding
    padding: 0!important
.modal-dialog
    max-width: 500px
    margin: auto
/* new */
.desktop-only
    display: none
@media only screen and (min-width: 992px)
    .split-modal
        flex-direction: row
    .split-modal-right
        display: flex
        flex-direction: column
    .mobile-only
        display: none
    .desktop-only
        display: block
    .split-modal-right
        flex-grow: 1
    .modal-dialog
        max-width: calc(500px + 350px)
</style>
