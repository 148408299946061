<template>
<div class="modal-body">
    <div>
        <div class="d-inline-block">
            <h1 style="color: orange;">1. </h1>
            <b style="color: orange;">Contact</b> admin (<a href="https://discord.app" target="_blank">Discord: raionus#8576</a> or use <a href="https://community.wanikani.com/t/300-vocabulary-lists-koohicafe-aka-floflo-a-wk-friendly-srs/48703" target="_blank">WK thread</a>). I will <b>verify</b> that the items can be processed correctly.
        </div>
    </div>
    <div>
        <h1 style="color: orange;">2. </h1>
        <p><b style="color: orange;">Finalize</b> the deal by sending me a donation on <a href="https://ko-fi.com/floflo" target="_blank">Ko-fi</a></p>
    </div>
    <div style="color: grey;">
        Orders are generally completed in 1-3 days.
    </div>
</div>
</template>

<script>
export default {
    name: "OrderProcess"
}
</script>

<style lang="sass" scoped>
h1
    display: inline-block
    padding-right: .25em
    min-width: 1em
    text-align: center
p
    display: inline-block
h1, p
    line-height: 2
</style>
