<template>
    <div>
        <h2>Search</h2>
        <slot name="msg"></slot>
        <div class="i-group flat col-12 col-sm-9 mx-auto">
            <div>
                <i class="fas fa-search"
                ></i>
            </div>
            <input
                type="search"
                list="book_list"
                placeholder="Search for titles..."
                v-model="search"
            />
            <datalist
                id="book_list"
                v-if="search.length > 0"
            >
                <option
                    v-for="(items, indx) in datalist"
                    :key="indx+'cc'"
                >{{items}}</option>
            </datalist>
            <div class="clickable" @click="$emit('open-options-modal')">
                <i class="fas fa-cog"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { isJapanese } from 'wanakana'

const wanakana = { isJapanese }

export default {
    name: 'Search',
    props: ['books'],
    data() {
        return {
            search: '',
            searchTimer: null,
        }
    },
    computed: {
        datalist() {
            const usingJapanese = isJapanese(this.search.slice(0, 5))
            const booksToShow = this.generateDataList()
                                .map((i) => (usingJapanese ? i.title_jp : i.title))
            return booksToShow
        },
    },
    methods: {
        sendOutUpdate() {
            //  send out value on a 100ms delay
            const func = () => {
                /* console.log('value updated with', this.search)
                this.$emit('search-val-update', this.search) */
                const emitValue = this.search === ''
                                    ? ['all']
                                    : this.generateDataList({ maxLen: 9999 })
                                    .map((i) => i.id)
                console.log('%cSearch%csendOutUpdate', window.ConsoleStyles.originComponent, window.ConsoleStyles.info, emitValue)
                this.$emit(
                    'search-val-update',
                    emitValue
                )
            }
            if (this.searchTimer) window.clearTimeout(this.searchTimer)
            this.searchTimer = window.setTimeout(func, 250)
        },
        generateDataList({ maxLen = 12 } = {}) {
            console.log('%cSearch%cBooksToShowForDatalist: normalizedSearch', window.ConsoleStyles.originComponent, window.ConsoleStyles.debug, this.search,)
            if (this.search === "") return []
            const usingJapanese = isJapanese(this.search.slice(0, 5))
            const normalizedSearch = !usingJapanese
                                    ? this.search.toLowerCase()
                                      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                                    : this.search
                                      .replace(/[！…。？⁉‼！、]/g, '')
                                      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            //  if search is short, only search from start of title
            const searchIsShort = normalizedSearch.length < 5
            console.log('%cbooksToShowForDatalist: normalizedSearch', window.ConsoleStyles.debug, normalizedSearch, this.search)
            const fSearchEN = (item) => {
                        if (item.title
                            .toLowerCase()
                            .match(searchIsShort
                            ? new RegExp(`^${normalizedSearch}`)
                            : normalizedSearch)
                        ) return true
                        return false
                    }
            const fSearchJP = (item) => {
                        if (item.title_jp
                            .replace(/[！…。？⁉‼！、]/g, '')
                            .match(searchIsShort
                            ? new RegExp(`^${normalizedSearch}`)
                            : normalizedSearch)
                        ) return true
                        return false
                    }
            const func = usingJapanese ? fSearchJP : fSearchEN
            let currentLen = 0
            const booksToShow = this.books
                                .filter((i) => {
                                    if (currentLen > maxLen) return false
                                    if (func(i)) {
                                        currentLen += 1
                                        return true
                                    }
                                    return false
                                })
            return booksToShow
        }
    },
    watch: {
        search() {
           this.sendOutUpdate()
        }
    }
}
</script>
