<template>
    <div class="modal-img-container">
        <img :src="getImg(items)" @error="items.filename = 'cover.jpg' " :alt="`${items.title} - Vocabulary List`" />
    </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
export default {
    name: 'ModalImage',
    data() {
        return {}
    },
    props: ['items'],
    methods: {
        getImg(items) {
            try {
                /* return require(`@/assets/images/covers/${items.filename ? items.filename : 'cover.jpg'}`) */
                if (items.filename === 'def') return require(`@/assets/svg/coffee vector.svg`)
                return require(`@/assets/images/covers/${items.filename}`)
            } catch (e) {
                console.warn(`Image not found ${items.filename} for book ${items.title}`)
                return require(`@/assets/svg/coffee vector.svg`)
            }
        },
    }
}
</script>

<style lang="sass" scoped>
.modal-img-container
    max-width: 350px
    img
        width: 100%
        height: auto
        border-radius: 4px 10px
</style>
